import React, {useDebugValue, useState} from 'react'
import { Container, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'

const ResultsEstimator = props => {  
  const [numberCustomers, setNumberCustomers] = useState(66);
  const [customerSatisfaction, setCustomerSatisfaction] = useState(7);

  const expectedReviews = parseInt(numberCustomers * (.25 * (customerSatisfaction > 0 ? (customerSatisfaction / 10) : 0)));

  return (
    <ContentContainer
      sx={{
        mt: 5,
        mb: 5,
      }}
      content={{
        variant: 'cards.paper'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ['center', 'center', 'center', 'flex-start'],
          flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
        }}
      >
        <Box
          sx={{
            width: ['100%', '80%', '70%', '48%'],
            marginBottom: '40px',
          }}
        >
          <Box
            sx={{
              display: ['none', 'none', 'none', 'block']
            }}
          >
            <ContentText
              content={[
                {
                  "text": "Review Estimator",
                  "color": "omegaDarker",
                  "variant": "h3"
                }
              ]}
            />
          </Box>
          <Box
            sx={{
              marginBottom: '25px',
            }}
          >
            <ContentText
              sx={{
                marginBottom: '0px',
              }}
              content={[
                {
                  "text": "Number of customers per month",
                  "color": "omega",
                  "variant": "h5"
                }
              ]}
            />
            <input
              type="range"
              min="1"
              max="250"
              onChange={(evt) => {
                setNumberCustomers(parseInt(evt.target.value));
              }}
              value={numberCustomers}
              style={{
                width: '100%',
                margin: 0,
              }}
              id="customers-slider"
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                lineHeight: '16px'
              }}
            >
              <Box>
                1
              </Box>
              <Box>
                250
              </Box>
            </Box>
          </Box>
          <Box>
            <ContentText
              sx={{
                marginBottom: '0px',
              }}
              content={[
                {
                  "text": "Typical customer satisfaction",
                  "color": "omega",
                  "variant": "h5"
                }
              ]}
            />
            <input
              type="range"
              min="1"
              max="10"
              onChange={(evt) => {
                setCustomerSatisfaction(parseInt(evt.target.value));
              }}
              value={customerSatisfaction}
              style={{
                width: '100%',
                margin: 0,
              }}
              id="customer-satisfaction-slider"
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                lineHeight: '16px'
              }}
            >
              <Box>
                1
              </Box>
              <Box>
                10
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: ['100%', '90%', '90%', '48%'],
            marginBottom: ['40px', '40px', '40px', '0px'],
          }}
        >
          <ContentContainer
            sx={{
              textAlign: 'center',
              padding: '60px 0 40px 0',
            }}
            content={{
              variant: 'cards.paper'
            }}
          >
            <Box
              sx={{
                marginBottom: '60px',
                fontWeight: 600
              }}
            >
              <Box
                sx={{
                  fontSize: '3.8rem',
                  lineHeight: '2.8rem',
                  color: '#0175ff',
                }}
              >
                {expectedReviews}
              </Box>
              <Box
                sx={{
                  fontSize: '1.3rem',
                }}
              >
                Reviews
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                
              }}
            >
              <Box
                sx={{
                  width: '50%',
                }}
              >
                <Box
                  sx={{
                    fontSize: '2rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  {numberCustomers}
                </Box>
                <Box
                  sx={{
          
                  }}
                >
                  Customers
                </Box>
              </Box>
              <Box
                sx={{
                  width: '50%',
                }}
              >
                <Box
                  sx={{
                    fontSize: '2rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  {`${customerSatisfaction}/10`}
                </Box>
                <Box
                  sx={{
          
                  }}
                >
                  Satisfaction
                </Box>
              </Box>
            </Box>
          </ContentContainer>
        </Box>
        <Box
          sx={{
            display: ['block', 'block', 'block', 'none']
          }}
        >
          <ContentText
            content={[
              {
                "text": "Review Estimator",
                "color": "omegaDarker",
                "variant": "h3"
              }
            ]}
          />
        </Box>
      </Box>
    </ContentContainer>
  )
}

export default ResultsEstimator
