import React from 'react'
import { graphql } from 'gatsby'
import { Container, Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import ContentText from '@solid-ui-components/ContentText'
import GatsbyConfig from '../../../gatsby-config';
import Features from '@solid-ui-blocks/Features/Block05'
import ContentImages from '@solid-ui-components/ContentImages'
import ResultsEstimator from '../../components/expected-results/ResultsEstimator';
import SignupForm from '../../components/expected-results/SignupForm';

const ExpectedResultsPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  

  return (
    <Layout {...props}>
      <Seo
        title='Review Estimator'
        description={`Get an idea of how many additional reviews you can get using Simple Review Management`}
        keywords={[]}
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Divider space='6' />
      <Container
        variant='full'
        sx={{

        }}
      >
        <Features content={{
          "text": [
            {
              "text": "How Many Reviews?",
              "variant": "h1",
              "space": 6
            }
          ],
          "collection": [],
        }} />
      </Container>
      <Container
        sx={{

        }}
      >
        <ContentText
          content={[
            {
              "text": "The number of additional reviews you will get depends on how many customers you have and how happy your customers are.",
              "color": "omegaDarker",
              "variant": "medium",
              "space": 5
            },
            {
              "text": "Use the review estimator to get an idea of what is possible for your business.",
              "color": "omegaDarker",
              "variant": "medium"
            },
          ]}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              maxWidth: '900px',
              width: '100%'
            }}
          >
            <ResultsEstimator />
          </Box>
        </Box>
      
        <ContentText
          sx={{
            marginLeft: '0px'
          }}
          content={[
            {
              "text": "Try Risk Free",
              "color": "omegaDarker",
              "variant": "h2"
            }
          ]}
        />
        <ContentText
          content={[
            {
              "text": "Our customers are only charged for the additional reviews that are the direct result of a review request.",
              "color": "omegaDarker",
              "variant": "medium"
            },
            {
              "text": "We can tell which reviews a company received all on it's own and which were spured on by a review request.",
              "color": "omegaDarker",
              "variant": "medium"
            }
          ]}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
            mb: 5,
          }}
        >
          <ContentImages
            sx={{
              maxWidth: ['600px', '600px', '600px', '800px']
            }}
            content={{images: [content['image'].images[0]]}}
            imagePosition='center'
          />
        </Box>

        <ContentText
          content={[
            {
              "text": "In the above graph black is the standard reviews the company got on its own and green is the additional reviews the review requests stirred up.",
              "color": "omegaDarker",
              "variant": "medium"
            },
            {
              "text": "This customer was only charged for the green reviews.",
              "color": "omegaDarker",
              "variant": "medium"
            },
            {
              "text": "There is no minimum charge. If you don't get any additinal reviews in a month you pay nothing that month. The customer in the graph paid nothing in July.",
              "color": "omegaDarker",
              "variant": "medium"
            }
          ]}
        />
      </Container>
      <Divider space='4' />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '900px'
          }}
        >
          <SignupForm content={{
            "text": [
              {
                "text": "Create account",
                "color": "omegaDarker",
                "variant": "h3",
                "space": 2
              }
            ],
            "form": {
              "action": "/create-account",
              "fields": [
                {
                  "identifier": "email",
                  "type": "TEXT",
                  "placeholder": {
                    "text": "Email Address"
                  },
                  "icon": {
                    "src": "../../assets/flexiblocks/icons/bx-envelope.svg",
                    "color": "alpha"
                  }
                },
                {
                  "identifier": "password",
                  "type": "PASSWORD",
                  "placeholder": {
                    "text": "Create Password"
                  },
                  "icon": {
                    "src": "../../assets/flexiblocks/icons/bx-lock-alt.svg",
                    "color": "alpha"
                  }
                }
              ],
              "buttons": [
                {
                  "text": "Start",
                  "type": "SUBMIT",
                  "variant": "secondary",
                  "width": "200px"
                }
              ]
            }
          }}/>
        </Box>
      </Box>
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ExpectedResultsBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/expected-results"] } }
    ) {
      nodes {
        ...NewCoolBlockContent
      }
    }
  }
`

export default ExpectedResultsPage
