import React, { useState, useEffect, useContext } from 'react'
import { Flex, Box, css, Spinner } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ContentButtons from '@solid-ui-components/ContentButtons'
import FormCheckbox from '@solid-ui-components/ContentForm/FormCheckbox'
import FormInput from '@solid-ui-components/ContentForm/FormInput'
import FormTextarea from '@solid-ui-components/ContentForm/FormTextarea'
import FormHidden from '@solid-ui-components/ContentForm/FormHidden'
import Modal from '@solid-ui-components/Modal'
import ListItem from '@solid-ui-components/ListItem'
import ContentText from '@solid-ui-components/ContentText'
import { BiCheckCircle } from 'react-icons/bi'
import { FormContext } from '@solid-ui-components/ContentForm'

const styles = {
    form: {
        position: `relative`
    },
    responseOverlay: {
        position: `absolute`,
        backgroundColor: `transparent`,
        width: `full`,
        height: `105%`,
        transition: `background-color 350ms ease-in`,
        textAlign: `center`,
        zIndex: -1,
        p: 3,
        top: 0,
        left: 0,
        active: {
            zIndex: 0,
            backgroundColor: `rgba(255,255,255,0.85)`
        }
    },
    buttonsWrapper: {
        display: `inline-flex`,
        flexWrap: `wrap`,
        justifyContent: `center`,
        '.button-group-button + .button-group-link': {
            flex: `100%`,
            ml: 0,
            mt: 3
        }
    }
}

const SignupForm = ({
  content: { identifier, text, collection, form : {
    buttons, action, fields
    }
  },
  reverse
}) => {
    const APP_DOMAIN = 'https://app.simplereviewmanagement.com';

    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState()
    const [errorMessage, setErrorMessage] = useState('')
  
    const { formValues, setFormValues } = useContext(FormContext)
    const formId = 'create-account-form'
  
    useEffect(() => {
      return () =>
        success !== undefined &&
        submitting === false &&
        setFormValues({
          ...formValues,
          [formId]: {}
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, submitting, setFormValues])
  
    const onChange = e => {
      setFormValues({
        ...formValues,
        [formId]: {
          ...formValues?.[formId],
          [e.target.name]: e.target.value
        }
      })
    }
  
    const onSubmit = e => {
      e.preventDefault();

      setSuccess(null);

      setTimeout(() => {
        const emailAddress = e.target.elements[0].value !== '' ? e.target.elements[0].value : null;
        const password = e.target.elements[1].value !== '' ? e.target.elements[1].value : null;
  
        let validData = true;
  
        if (emailAddress === null) {
          setSuccess(false);
          setErrorMessage('Email is required');
          validData = false;
        } else if (emailAddress.match(/\S+@\S+\.\S+/) === null) {
          setSuccess(false);
          setErrorMessage('Invalid email');
          validData = false;
        } else if (password === null) {
          setSuccess(false);
          setErrorMessage('Password is required');
          validData = false;
        } else if (password.length <= 7) {
          setSuccess(false);
          setErrorMessage('Minimum password length 7 characters');
          validData = false;
        }
    
        if (validData) {
          setSubmitting(true);
  
          const data = {
            email: emailAddress,
            password: password,
          };
      
          fetch(
            `${APP_DOMAIN}/api/auth/user`,
            {
              method: 'POST',
              credentials: 'include',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            },
          ).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              setSuccess(true);
              setSubmitting(false); 

              if (
                typeof window.dataLayer !== 'undefined'
              ) {
                window.dataLayer.push({'event':'create-account-success'});
              }
            } else {
              response.json().then((responseBody) => {
                if (responseBody.errors && responseBody.errors.length) {
                  setErrorMessage(responseBody.errors[0]);
                } else {
                  setErrorMessage('Please try again');
                }
                setSuccess(false);
                setSubmitting(false);
              });
            }
          }).catch((e) => {
            setErrorMessage('Please try again');
            setSuccess(false);
            setSubmitting(false);
          });
        }
      }, 100)
    }

  return (
    <Box
      id={identifier}
      contentStyles={{ maxWidth: 500, p: 0}}
    >
        <Flex
            sx={{
            alignItems: `stretch`,
            flexDirection: [
                reverse ? `column-reverse` : `column`,
                reverse ? `row-reverse` : `row`
            ]
            }}
        >
          {success === true
            ? <Box sx={{ flex: 1, p: 1 }}>
                <Reveal effect='fadeInUp'>
                    <Box sx={{ textAlign: `center`, width: `100%` }}>
                        <BiCheckCircle size='84' css={css({ color: `success` })} />
                        <ContentText content={[
                            {
                                color: "success",
                                space: null,
                                text: "Account Created",
                                textGroup: null,
                                variant: "h3",
                            },
                            {
                                color: "omegaDarker",
                                space: 5,
                                text: "You are already logged in to your new account. Use the button below to access it.",
                                textGroup: null,
                            }
                        ]}
                        />
                        <ContentButtons content={[{
                                bg: null,
                                icon: null,
                                link: APP_DOMAIN,
                                target: null,
                                text: "Go to Account",
                                type: "PAGE",
                                variant: "secondary",
                                width: "200px"
                            }]}
                        />
                    </Box>
                </Reveal>
            </Box>
            : <Box sx={{ flex: 1, p: 5 }}>
                {text && (
                    <>
                    <Box sx={{ textAlign: `center` }}>
                        <ContentText content={text} />
                    </Box>
                    </>
                )}
                {collection?.map((props, index) => (
                    <ListItem key={`item-${index}`} {...props} compact />
                ))}
                <form
                    css={css(styles.form)}
                    onSubmit={onSubmit}
                    method='POST'
                    action={action}
                    demo={action ? undefined : 'demo'}
                >
                  <Box
                    css={{height: '40px'}}
                  >
                      {success === false && <Reveal effect='fadeInDown'>
                        <ContentText
                          sx={{
                            backgroundColor: 'red',
                            padding: '0px 8px',
                            borderRadius: '3px',
                          }}
                          content={{
                            color: "white",
                            space: 2,
                            text: errorMessage,
                            textGroup: null,
                            variant: null,
                          }}
                        />
                      </Reveal>}
                    </Box>
                    <Box variant='forms.row'>
                        {fields?.map(({ identifier, value, ...props }, index) => {
                        let Component
                        switch (props.type) {
                            case 'PASSWORD':
                            case 'EMAIL':
                            case 'TEXT':
                            Component = FormInput
                            break
                            case 'TEXTAREA':
                            Component = FormTextarea
                            break
                            case 'CHECKBOX':
                            Component = FormCheckbox
                            break
                            case 'HIDDEN':
                            Component = FormHidden
                            break
                            default:
                            break
                        }

                        return (
                            <Box
                            key={`index-${index}`}
                            variant={props.compact ? 'forms.compact' : 'forms.full'}
                            >
                            <Component
                                {...props}
                                onChange={onChange}
                                name={identifier}
                                id={`${formId}.${identifier}`}
                                value={formValues?.[formId]?.[identifier] || value || undefined}
                            />
                            </Box>
                        )
                        })}
                    </Box>
                    <Box sx={{ textAlign: `center`, marginTop: '25px'}}>
                        <ContentButtons
                            content={buttons}
                            wrapperStyles={styles.buttonsWrapper}
                        />
                    </Box>
                    <Box
                        sx={styles.responseOverlay}
                        css={(submitting) && styles.responseOverlay.active}
                    >
                        {submitting && (
                            <Reveal effect='fadeInDown'>
                                <Spinner size='64' color='alpha' />
                            </Reveal>
                        )}
                    </Box>
                </form>
            </Box>}
        </Flex>
    </Box>
  )
}

export default SignupForm
